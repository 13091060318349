/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "description_variant_default": "",
  "description_size_default": "text-body-sm mt-3",
  "description_colors_default": "text-gray-200",
  "row_variant_default": "flex justify-between flex-col flex-wrap sm:flex-row md:flex-nowrap",
  "row_size_default": "gap-x-6",
  "row_colors_default": "",
  "column_variant_default": "",
  "column_size_default": "mb-6 md:mb-0",
  "column_colors_default": "",
  "editors_variant_default": "",
  "editors_size_default": "text-body-sm mt-3",
  "editors_colors_default": "text-gray-200",
  "list_title_variant_default": "",
  "list_title_size_default": "text-headline-2xs mb-3",
  "list_title_colors_default": "text-white",
  "list_item_variant_default": "hover:underline",
  "list_item_size_default": "text-body-sm",
  "list_item_colors_default": "text-gray-200",
  "list_variant_default": "flex flex-col",
  "list_size_default": "gap-y-3",
  "list_colors_default": "",
  "sites_title_variant_default": "text-center",
  "sites_title_size_default": "text-headline-3xs mt-6 mb-1",
  "sites_title_colors_default": "text-white",
  "sites_item_variant_default": "no-underline hover:underline",
  "sites_item_size_default": "text-body-xs",
  "sites_item_colors_default": "text-gray-200",
  "sites_variant_default": "flex flex-wrap justify-center",
  "sites_size_default": "max-w-[720px] w-full gap-x-2.5 mx-auto",
  "sites_colors_default": "",
  "logo_variant_default": "relative",
  "logo_size_default": "",
  "logo_colors_default": "[&>svg>path]:fill-white",
  "soMe": {},
  "soMe_headline_variant_default": "",
  "soMe_headline_size_default": "text-headline-2xs mt-6 w-full",
  "soMe_headline_colors_default": "text-white",
  "soMe_group_colors_default": "",
  "soMe_group_size_default": "gap-3",
  "soMe_group_variant_default": "flex flex-wrap",
  "copyright_variant_default": "text-center",
  "copyright_size_default": "text-body-xs mt-6",
  "copyright_colors_default": "text-gray-200",
  "variant_default": "relative wings z-1",
  "size_default": "pt-6 pb-24 px-grid-m mt-6 max-w-content mx-auto md:pb-12 lg:px-0",
  "colors_default": "bg-gray-950 wings-gray-950"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Footer");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;