import { tw } from '@/utils/tw';
import BaseButtonTheme from 'base/components/Button/theme';

const { Icon } = BaseButtonTheme;

const Button = tw.theme({
  extend: BaseButtonTheme,
  base: ['focus:ring-2', 'focus:ring-offset-2', 'active:ring-0', 'active:ring-offset-0', 'border-0'],
  variants: {
    colors: {
      primary: [
        'bg-gray-950',
        'text-white',
        'hover:bg-gray-600',
        'focus:bg-gray-600',
        'focus:ring-blue-900',
        'active:bg-gray-800',
      ],
      secondary: [
        'bg-gray-100',
        'text-black',
        'hover:bg-gray-200',
        'focus:bg-gray-200',
        'focus:ring-blue-900',
        'active:bg-gray-300',
      ],
    },
    size: {
      small: ['text-headline-3xs', 'px-4', 'py-2.25', 'h-8'],
      medium: ['text-headline-2xs', 'px-6', 'py-3', 'h-10'],
      large: ['text-headline-2xs', 'px-6', 'py-4', 'h-12'],
    },
  },
});

export default Object.assign(Button, { Icon });
