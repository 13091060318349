const red = {
  50: '#FFF0F0',
  100: '#FFDDDE',
  200: '#FFC1C3',
  300: '#FF9699',
  400: '#FF5A5F',
  500: '#FF272E',
  600: '#FB070E',
  700: '#C60107',
  800: '#AF050A',
  900: '#900C10',
  950: '#4F0002',
};

const yellow = {
  50: '#FEFCE8',
  100: '#FFFAC2',
  200: '#FFF089',
  300: '#FFDe37',
  400: '#FDCd12',
  500: '#ECB306',
  600: '#CC8A02',
  700: '#A36105',
  800: '#864C0d',
  900: '#723E11',
  950: '#432005',
};

const blue = {
  50: '#EBF9FF',
  100: '#D3EFFF',
  200: '#B1E4FF',
  300: '#7BD7FF',
  400: '#3DBDFF',
  500: '#109AFF',
  600: '#0076FF',
  700: '#005eFF',
  800: '#004CD0',
  900: '#064CB4',
  950: '#092a62',
};

const gray = {
  50: '#F6F6F6',
  100: '#E7E7E7',
  200: '#D1D1D1',
  300: '#B0B0B0',
  400: '#888888',
  500: '#6D6D6D',
  600: '#5D5D5D',
  700: '#4F4F4F',
  800: '#454545',
  900: '#3D3D3D',
  950: '#0E0E0E',
};

const colors = {
  red: {
    DEFAULT: red[700],
    ...red,
  },
  yellow: {
    DEFAULT: yellow[300],
    ...yellow,
  },
  blue: {
    DEFAULT: blue[900],
    ...blue,
  },
  gray,
};

module.exports = colors;
