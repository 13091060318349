import type { FunctionComponent } from 'react';

import ChevronLeft from './svg/chevron-left.svg';
import ChevronRight from './svg/chevron-right.svg';

export const iconsDictionary: Record<string, FunctionComponent> = {
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  sliderArrow: ChevronLeft,
  sliderForwardArrow: ChevronRight,
  teaserArrow: ChevronRight,
};
