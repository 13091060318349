import { useAppState } from '@/hooks/useAppState';
import { StandaloneMain } from 'base/components/Main';

const staticPages = ['kontakta-oss', 'om-oss', 'om-cookies', 'nyhetsbrev', 'tipsa-oss', 'lasarpanelen'];

export const HantStandaloneMain: typeof StandaloneMain = (props) => {
  const [{ publishedUrl }] = useAppState();

  const isStaticPage = staticPages.some((page) => publishedUrl?.startsWith(`/${page}`));

  return <StandaloneMain data-page={isStaticPage ? 'static' : undefined} {...props} />;
};
