/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_plain": "ring-primary-500/20 border-none bg-none text-primary-900 hover:border-primary-600 hover:bg-primary-600 hover:text-white focus:border-primary-800 focus:bg-primary-800 focus:text-white active:border-primary-800 active:bg-primary-800 active:text-white",
  "colors_filled": "ring-primary-500/20 border-none bg-primary-900 text-white hover:border-primary-600 hover:bg-primary-600 hover:text-white focus:border-primary-800 focus:bg-primary-800 focus:text-white active:border-primary-800 active:bg-primary-800 active:text-white",
  "colors_border": "ring-primary-500/20 border-primary-900 bg-none text-primary-900 hover:border-primary-600 hover:bg-primary-600 hover:text-white focus:border-primary-800 focus:bg-primary-800 focus:text-white active:border-primary-800 active:bg-primary-800 active:text-white",
  "size_default": "w-8 h-8",
  "variant_default": "inline-flex items-center justify-center transition-all duration-200 ease-in-out ring-0 border rounded-full focus:outline-none hover:ring-0 hover:outline-none active:ring-0 active:outline-none border-none ring-offset-0 outline-none focus:ring-2 focus:ring-offset-2",
  "colors_primary": "bg-gray-950 text-white ring-blue-900 hover:bg-gray-600 focus:bg-gray-600 active:bg-gray-800",
  "colors_secondary": "bg-gray-100 text-black ring-blue-900 hover:bg-gray-200 focus:bg-gray-200 active:bg-gray-300"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SoMe");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;