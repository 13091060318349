const typographyPlugin = require('tailwind-utils/typography.plugin');

module.exports = (typographyConfig) => (theme) => {
  const typograph = (name) => typographyPlugin.getUtilities(theme, typographyConfig)[`.text-${name}`];

  return {
    DEFAULT: {
      css: {
        '--tw-prose-body': theme('colors.black'),
        '--tw-prose-headings': theme('colors.black'),
        '--tw-prose-links': theme('colors.blue.900'),
        '--tw-prose-bullets': theme('colors.gray.950'),
        '--tw-prose-counters': theme('colors.gray.950'),

        'max-width': '100%',

        ...typograph('body-sm'),

        h1: {
          ...typograph('headline-xl'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),

          '@media screen(sm)': typograph('headline-3xl'),
        },

        h2: {
          ...typograph('headline-md'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),

          '@media screen(sm)': typograph('headline-xl'),
        },

        h3: {
          ...typograph('headline-sm'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),

          '@media screen(sm)': typograph('headline-md'),
        },

        h4: {
          ...typograph('headline-2xs'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),

          '@media screen(sm)': typograph('headline-xs'),
        },

        h5: {
          ...typograph('headline-3xs'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),

          '@media screen(sm)': typograph('headline-2xs'),
        },

        h6: {
          ...typograph('headline-3xs'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),
        },

        p: {
          ...typograph('body-sm'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),

          '@media screen(sm)': typograph('body-md'),
        },

        a: {
          textDecoration: 'none',
          fontWeight: '700',

          '&:hover': {
            color: theme('colors.blue.900'),
            textDecoration: 'underline',
          },
          '&:visited': {
            color: theme('colors.blue.950'),
          },
        },

        ol: {
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),
        },

        ul: {
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),
        },

        li: {
          ...typograph('body-sm'),
          marginTop: 0,
          marginBottom: 0,
          paddingLeft: 0,

          '@media screen(sm)': typograph('body-md'),
        },
      },
    },

    'author-details': {
      css: {
        ...typograph('body-sm'),

        p: {
          ...typograph('body-xs'),

          '@media screen(sm)': typograph('body-sm'),
        },
      },
    },

    factbox: {
      css: {
        ...typograph('body-xs'),

        h2: {
          ...typograph('headline-sm'),
          marginTop: theme('spacing[5]'),

          '@media screen(sm)': typograph('headline-md'),
        },

        h3: {
          ...typograph('headline-2xs'),
          marginTop: theme('spacing[5]'),
          marginBottom: theme('spacing[2]'),

          '@media screen(sm)': typograph('headline-xs'),
        },

        p: {
          ...typograph('body-xs'),

          '@media screen(sm)': typograph('body-sm'),
        },

        li: {
          ...typograph('body-xs'),

          '@media screen(sm)': typograph('body-sm'),
        },
      },
    },

    coverbox: {
      css: {
        ...typograph('body-xs'),

        p: {
          ...typograph('body-xs'),

          '@media screen(sm)': {
            ...typograph('body-sm'),
          },
        },
      },
    },
  };
};
