import { tw } from '@/utils/tw';
import BaseTextFieldTheme from 'base/components/TextField/theme';

const Icon = tw.theme({
  extend: BaseTextFieldTheme.Icon,
  base: ['text-gray-950', 'group-data-[status=error]:text-red-700'],
  variants: {
    size: {
      small: ['h-5', 'w-5'],
    },
    secondary: {
      true: [
        'group-data-[filled]:bottom-1.5',
        'group-data-[filled]:w-5',
        'group-data-[filled]:h-5',

        'group-focus-within:bottom-1.5',
        'group-focus-within:w-5',
        'group-focus-within:h-5',
      ],
    },
  },
});

const TextField = tw.theme({
  extend: BaseTextFieldTheme,
  slots: {
    caption: ['text-input-sm', 'text-gray-950', 'group-data-[status=error]:text-red-700'],
    input: [
      'px-2.5',
      'text-input-md',
      'ring-gray-600',
      'placeholder:text-gray-600',

      'group-data-[status=error]:outline-0',
      'group-data-[status=error]:ring-red-700',

      'hover:not-focus:bg-gray-50',
      'hover:not-focus:ring-gray-950',

      'focus:outline-0',
      'focus:ring-blue-900',
      'focus:bg-white',
    ],
    label: ['text-gray-950'],
  },
  variants: {
    variant: {
      primary: {
        input: ['py-2', 'peer-[.absolute]/icon:pr-9'],
        label: ['text-input-sm'],
      },
      secondary: {
        input: ['pt-5.5', 'pb-1.5', 'peer-[.absolute]/icon:pr-9'],
        label: [
          'text-input-md',
          'transition-all',
          'left-2.5',

          'group-data-[filled]:text-input-xs',
          'group-data-[filled]:top-0',

          'group-data-[status=error]:text-red-700',

          'group-focus-within:top-0',
          'group-focus-within:text-input-xs',
          'group-focus-within:text-blue-900',
        ],
      },
    },
  },
});

export default Object.assign(TextField, { Icon });
