import { tw } from '@/utils/tw';
import BaseNavigationTheme from 'base/components/Navigation/theme';

const Button = tw.theme({
  extend: BaseNavigationTheme.Button,
  variants: {
    size: {
      small: 'border-0',
    },
  },
  defaultVariants: {
    // @ts-expect-error: Bug in tailwind-variants type inheritance
    size: 'medium',
  },
});

const BaseLinkVariants = BaseNavigationTheme.Link.variants;

const Link = tw.theme({
  variants: {
    colors: {
      bar: ['text-gray-950', 'lg:text-gray-950'],
      drawer: ['text-gray-950'],
    },
    size: {
      ...BaseLinkVariants.size,
    },
    variant: {
      bar: ['text-headline-3xs', 'inline-block'],
      drawerPrimary: ['text-headline-2xs', 'block', 'hover:underline', 'py-3.5', 'sm:py-0'],
      drawerSecondary: ['text-body-sm', 'hover:underline', 'block', 'py-3', 'sm:py-0'],
    },
  },
});

export default Object.assign({}, { Button, Link });
