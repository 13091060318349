import { Component } from '@/types/component';
import { tw } from '@/utils/tw';
import { StandaloneEdge, StandaloneEdgeProps } from 'base/components/Edge';
import localFont from 'next/font/local';

const fontPrimary = localFont({
  display: 'swap',
  preload: false,
  src: [
    { path: '../../../public/fonts/NotoSans_normal_variable.woff2', style: 'normal', weight: '100 900' },
    { path: '../../../public/fonts/NotoSans_italic_variable.woff2', style: 'italic', weight: '100 900' },
  ],
  variable: '--font-primary',
});

export const HantStandaloneEdge: Component<StandaloneEdgeProps> = ({ children }) => {
  return <StandaloneEdge className={tw.join(fontPrimary.variable, 'bg-gray-100')}>{children}</StandaloneEdge>;
};
