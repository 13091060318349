import { tw } from '@/utils/tw';
import BaseHamburgerTheme from 'base/components/Hamburger/theme';

const Hamburger = tw.theme({
  extend: BaseHamburgerTheme,
  slots: {
    base: `h-6 w-6`,
    segment: `top-[calc(50%_-_1px)] w-5 bg-gray-950 px-1`,
  },
  variants: {
    animation: {
      secondary: {
        segment: `last-of-type:translate-y-1.5 first-of-type:-translate-y-1.5`,
      },
    },
  },
});

export default Hamburger;
